import { autenticacion } from "~/library/firebase"
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

const captachaId = "sign-in-button";

const registroService = {
    async enviarCodigoRegistro(numeroCelular){
        // Generamos el reCapcha
        this.generarRecapcha();
        // reCapcha generado correctamente, enviamos el código al número de celular
        let result = this.enviarCodigoNumero(numeroCelular);
        return result;
    },

    async generarRecapcha(){
        window.recaptchaVerifier = new RecaptchaVerifier(captachaId, {
            'size': 'invisible',
            'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
            }
        }, autenticacion);
        
    },

    async enviarCodigoNumero(numeroCelular){
        return new Promise((resolve, reject) => {
            let appVerifier = window.recaptchaVerifier;

            signInWithPhoneNumber(autenticacion, numeroCelular, appVerifier)
                .then((confirmationResult) => {
                    window.confirmationResult = confirmationResult;
                    resolve(true);
                }).catch((error) => {
                    reject(error);
                });
        });
    },

    async verificarCodigo(codigo){
        return new Promise((resolve, reject) => {
            let confirmationResult = window.confirmationResult;
            confirmationResult.confirm(codigo).then((result) => {
            // User signed in successfully.
                const user = result.user;
                resolve(user);
            // ...
            }).catch((error) => {
                reject(error)
            // User couldn't sign in (bad verification code?)
            // ...
            });
        });
    },
}

export default registroService