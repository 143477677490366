import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const configuracion = {
    apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
    authDomain: process.env.VUE_APP_GOOGLE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_GOOGLE_DATABASE_URL,
    projectId: process.env.VUE_APP_GOOGLE_PROJECT_ID,
    storageBucket: '',
    messagingSenderId: process.env.VUE_APP_GOOGLE_SENDER_ID,
    appId: process.env.VUE_APP_GOOGLE_APP_ID
};

const app = initializeApp(configuracion);
export const autenticacion = getAuth(app);